export const API_ENDPOINTS = {
  PRODUCTS: "/products",
  PRODUCTS_POPULAR: "/popular-products",
  PRODUCTS_REVIEWS: "/reviews",
  PRODUCTS_REVIEWS_ABUSE_REPORT: "/abusive_reports",
  PRODUCTS_QUESTIONS: "/questions",
  FEEDBACK: "/feedbacks",
  CATEGORIES: "/categories",
  TYPES: "/types",
  TAGS: "/tags",
  SHOPS: "/shops",
  AUTHORS: "/authors",
  AUTHORS_TOP: "/top-authors",
  MANUFACTURERS: "/manufacturers",
  MANUFACTURERS_TOP: "/top-manufacturers",
  COUPONS: "/coupons",
  COUPONS_VERIFY: "/coupons/verify",
  ORDERS: "/orders",
  ORDERS_REFUNDS: "/refunds",
  ORDERS_PAYMENT: "/orders/payment",
  ORDERS_CHECKOUT_VERIFY: "/orders/checkout/verify",
  ORDERS_DOWNLOADS: "/downloads",
  GENERATE_DOWNLOADABLE_PRODUCT_LINK: "/downloads/digital_file",
  USERS: "/users",
  USERS_ADDRESS: "/address",
  USERS_ME: "/user/profile_show/",

  USERS_LOGIN: "/user/token-auth/",

  USERS_REGISTER: "/user/register/",

  USERS_FORGOT_PASSWORD: "/user/reset_password_request_token/",

  USERS_VERIFY_FORGOT_PASSWORD_TOKEN: "/verify-forget-password-token",
  USERS_RESET_PASSWORD: "/user/reset_password_update_pwd_by_token/",

  USERS_CHANGE_PASSWORD: "/change-password",
  USERS_LOGOUT: "/logout",
  USERS_SUBSCRIBE_TO_NEWSLETTER: "/subscribe-to-newsletter",
  USERS_CONTACT_US: "/customer/cif_contact_us_sending_message_create/",
  USERS_WISHLIST: "/my-wishlists",
  WISHLIST: "/wishlists",
  USERS_WISHLIST_TOGGLE: "/wishlists/toggle",
  SOCIAL_LOGIN: "/social-login-token",
  SEND_OTP_CODE: "/send-otp-code",
  VERIFY_OTP_CODE: "/user/verify_email/",
  USER_MARKET_PLACE_STATUS_UPDATE: "/user/user_profile_additional_update/",
  USER_MARKET_PLACE_STATUS_STATUS: "/user/user_profile_additional_show/",

  CUSTOMER_PRODUCT_CATEGORY_NAME_SHOW: "/customer/product_category_name_show/",
  CUSTOMER_PRODUCT_SEARCH_GRID_SHOW: "/customer/Product_Search_grid_show/",
  CUSTOMER_MARKET_PLACE_SHOW: "/customer/marketplace_show/",
  CUSTOMER_BUSINESS_PROFILE_BY_WEB_URL:
    "/business/business_profile_by_web_url_show/",

  OTP_LOGIN: "/otp-login",
  UPDATE_CONTACT: "",
  SETTINGS: "/settings",
  UPLOADS: "/attachments",
  MY_QUESTIONS: "/my-questions",
  MY_REPORTS: "/my-reports",
  CARDS: "/cards",
  SET_DEFAULT_CARD: "/set-default-card",
  SAVE_PAYMENT_METHOD: "/save-payment-method",
  PAYMENT_INTENT: "/payment-intent",
  BEST_SELLING_PRODUCTS: "/best-selling-products",
  SEND_VERIFICATION_EMAIL: "/email/verification-notification",
  USERS_UPDATE_EMAIL: "/update-email",
  STORE_NOTICES: "store-notices",
  NEAR_SHOPS: "/near-by-shop",
};
