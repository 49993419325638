import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";

import enBanner from './locales/en/banner.json'
import enCommon from './locales/en/common.json'
import enFaq from './locales/en/faq.json'
import enGdpr from './locales/en/gdpr.json'
import enPolicy from './locales/en/policy.json'
import enTerms from './locales/en/terms.json'

i18n.use(initReactI18next).init({
    resources: {
        en: {
            common: { ...enCommon },
            banner: { ...enBanner },
            faq: { ...enFaq },
            policy: { ...enPolicy },
            terms: { ...enTerms },
            gdpr: { ...enGdpr },
        }
    }, // Where we're gonna put translations' files
    lng: "en",     // Set the initial language of the App
});