import { useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import type { AppProps } from "next/app";

// interface QueryProviderProps {
//     pageProps: AppProps["pageProps"];
// }

export default function QueryProvider({ pageProps, children }: React.PropsWithChildren<any>) {
    const [queryClient] = useState(() => new QueryClient());

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate
            // state={
            //     //@ts-ignore
            //     pageProps.dehydratedState
            // }
            >
                {children}
            </Hydrate>
            {/* <ReactQueryDevtools /> */}
        </QueryClientProvider>
    );
}
