export const FilterIcon: React.FC<React.SVGAttributes<{}>> = (props) => (
	<svg {...props} viewBox="0 0 18 14">
		<path
			d="M942.581,1295.564H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1295.564,942.581,1295.564Z"
			transform="translate(-925 -1292.064)"
			fill="currentColor"
		/>
		<path
			d="M942.581,1951.5H925.419c-.231,0-.419-.336-.419-.75s.187-.75.419-.75h17.163c.231,0,.419.336.419.75S942.813,1951.5,942.581,1951.5Z"
			transform="translate(-925 -1939.001)"
			fill="currentColor"
		/>
		<path
			d="M1163.713,1122.489a2.5,2.5,0,1,0,1.768.732A2.483,2.483,0,0,0,1163.713,1122.489Z"
			transform="translate(-1158.213 -1122.489)"
			fill="currentColor"
		/>
		<path
			d="M2344.886,1779.157a2.5,2.5,0,1,0,.731,1.768A2.488,2.488,0,0,0,2344.886,1779.157Z"
			transform="translate(-2330.617 -1769.425)"
			fill="currentColor"
		/>
	</svg>
);
