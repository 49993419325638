import React from "react";
import { ArrowNext, ArrowPrev } from "../icons";
import { Swiper, SwiperSlide, Navigation } from "../ui/slider";
import { useTranslation } from "react-i18next";

const offerSliderBreakpoints = {
    320: {
        slidesPerView: 1,
        spaceBetween: 0,
    },
    580: {
        slidesPerView: 2,
        spaceBetween: 16,
    },
    1024: {
        slidesPerView: 3,
        spaceBetween: 16,
    },
    1920: {
        slidesPerView: 4,
        spaceBetween: 24,
    },
};

export default function PromotionSlider({ sliders }: { sliders: any[] }) {
    const { t } = useTranslation();
    return (
        <div className="border-t border-border-200 bg-light px-6 py-5 md:p-8">
            <div className="relative">
                <Swiper
                    id="offer"
                    //TODO: need discussion
                    // loop={true}
                    breakpoints={offerSliderBreakpoints}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: ".next",
                        prevEl: ".prev",
                    }}
                >
                    {sliders?.map((d) => (
                        <SwiperSlide key={d.id}>
                            <img
                                className="h-auto w-full"
                                src={d.original}
                                alt={d.id}
                                width="580"
                                height="270"
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className="prev absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:border-accent hover:bg-accent hover:text-light ltr:-left-4 rtl:-right-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-left-5 rtl:md:-right-5"
                    role="button"
                >
                    <span className="sr-only">{t("common:text-previous")}</span>
                    <ArrowPrev width={18} height={18} />
                </div>
                <div
                    className="next absolute top-2/4 z-10 -mt-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-border-200 border-opacity-70 bg-light text-heading shadow-xl transition-all duration-200 hover:border-accent hover:bg-accent hover:text-light ltr:-right-4 rtl:-left-4 md:-mt-5 md:h-9 md:w-9 ltr:md:-right-5"
                    role="button"
                >
                    <span className="sr-only">{t("common:text-next")}</span>
                    <ArrowNext width={18} height={18} />
                </div>
            </div>
        </div>
    );
}
