import React from 'react';
export const ShoulderBags: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="18.03"
      viewBox="0 0 13 18.03"
      fill="currentColor"
      {...props}
    >
      <g data-name="Shoulder bags">
        <path
          data-name="Path 21816"
          d="M1.402 5.447c.631-.651 2.027-1.427 5.1-1.427s4.467.776 5.1 1.427a2.129 2.129 0 01.635 1.6 88.77 88.77 0 00-.01 3.256 17.576 17.576 0 01-11.445 0 86.56 86.56 0 00-.01-3.256 2.129 2.129 0 01.635-1.6zm1.032-4.8A.167.167 0 012.601.48h1.093a.167.167 0 01.167.167v.851a1.117 1.117 0 001.114 1.117h3.047A1.116 1.116 0 009.137 1.5V.649a.167.167 0 01.167-.167h1.093a.167.167 0 01.167.167v3.572a12.685 12.685 0 00-8.13 0V.649zm9.9 15.887c-.822.951-3.94.985-5.271 1-.243 0-.437 0-.563.012s-.32-.009-.563-.012c-1.331-.015-4.449-.049-5.271-1a.705.705 0 01-.175-.584c.165-1.228.238-3.6.268-5.091a17.872 17.872 0 003.763.795v.195a.975.975 0 00.974.974h2.01a.975.975 0 00.974-.974v-.195a17.87 17.87 0 003.762-.795c.03 1.495.1 3.863.268 5.091a.7.7 0 01-.175.584zM6.5 11.723q.764 0 1.512-.061v.186a.507.507 0 01-.506.506h-2.01a.507.507 0 01-.506-.506v-.186q.747.06 1.511.061zM.027 15.887a1.164 1.164 0 00.285.953c.96 1.11 4.107 1.145 5.62 1.162.246 0 .44 0 .554.011h.027c.114-.007.308-.009.554-.011 1.512-.017 4.659-.051 5.62-1.162a1.164 1.164 0 00.285-.953c-.127-.948-.226-2.9-.27-5.358a.238.238 0 00-.011-.066v-.005a91.065 91.065 0 01.007-3.4 2.615 2.615 0 00-.766-1.937 3.788 3.788 0 00-.9-.674v-3.8a.635.635 0 00-.634-.634H9.305a.635.635 0 00-.634.634v.851a.648.648 0 01-.648.648H4.975a.649.649 0 01-.648-.648V.647a.635.635 0 00-.634-.634H2.6a.635.635 0 00-.634.634v3.8a3.789 3.789 0 00-.9.674A2.615 2.615 0 00.3 7.058c.023.714.025 2.018.007 3.4v.006a.233.233 0 00-.011.066c-.026 1.444-.1 4.061-.27 5.358z"
          fill="currentColor"
          stroke="currentColor"
          strokeWidth=".03"
        />
      </g>
    </svg>
  );
};
