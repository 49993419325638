import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import cn from "classnames";
import * as groupIcons from "../../../components/icons/groups";
// import { useRouter } from "next/router";
// import Link from "@/components/ui/link";
// import { useTypes } from "@/framework/type";
// import useHomepage from "@/lib/hooks/use-homepage";
import Scrollbar from "../../ui/scrollbar";
import { getIcon } from "../../../utils/get-icon";
import { CaretDown } from "../../icons/caret-down";
import { ArrowDownIcon } from "../../icons/arrow-down";
import { Type } from "../../../types/index";
import useHomepage from "../../../utils/hooks/use-homepage";
import { useLocation } from "react-router-dom";

interface GroupsMenuProps {
    className?: string;
    groups?: Type[];
    defaultGroup?: Type;
    variant?: "colored" | "minimal";
    showIcon?: boolean;
    onItemClick?: (item: Type) => void;
}

// console.log("groupIcons => ", groupIcons.['DressIcon']);

const GroupsMenu: React.FC<GroupsMenuProps> = ({
    className,
    groups,
    defaultGroup,
    variant = "colored",
    showIcon = false,
    onItemClick,
}) => {
    const location = useLocation();
    // const router = useRouter();
    const selectedMenu =
        groups?.find((type) => location.pathname.includes(type?.slug)) ?? defaultGroup;
    const SelectedMenuIcon = selectedMenu?.icon;
    // const selectedMenu = defaultGroup;

    // console.log("selectedMenu => ", selectedMenu);
    // console.log("selectedMenu?.icon => ", selectedMenu?.icon);

    return (
        <Menu as="div" className="relative inline-block ltr:text-left rtl:text-right">
            <Menu.Button
                className={cn(
                    "flex h-11 shrink-0 items-center text-sm font-semibold text-heading focus:outline-0 md:text-base xl:px-4",
                    {
                        "rounded-lg border border-border-200 bg-gray-50 px-3":
                            variant === "minimal",
                        "rounded border-border-200 bg-light xl:min-w-150 xl:border xl:text-accent":
                            variant === "colored",
                    },
                    className
                )}
            >
                {({ open }) => (
                    <>
                        {variant === "colored" && selectedMenu?.icon && (
                            <span className="flex h-5 w-5 items-center justify-center ltr:mr-2 rtl:ml-2">
                                {typeof selectedMenu?.icon === "string" ? (
                                    getIcon({
                                        iconList: groupIcons,
                                        iconName: selectedMenu?.icon,
                                        className: "max-h-full max-w-full",
                                    })
                                ) : (
                                    <SelectedMenuIcon />
                                )}
                            </span>
                        )}
                        {!showIcon && (
                            <span className="whitespace-nowrap text-sm font-semibold">
                                {selectedMenu?.name}
                            </span>
                        )}
                        {!showIcon && (
                            <span className="flex pt-1 ltr:ml-auto ltr:pl-2.5 rtl:mr-auto rtl:pr-2.5">
                                {variant === "colored" && (
                                    <CaretDown
                                        className={open ? "rotate-180 transform" : undefined}
                                    />
                                )}

                                {variant === "minimal" && (
                                    <ArrowDownIcon
                                        className={cn("h-3 w-3", {
                                            "rotate-180 transform": open,
                                        })}
                                    />
                                )}
                            </span>
                        )}
                    </>
                )}
            </Menu.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    as="ul"
                    className={cn(
                        "absolute mt-2 h-56 max-h-56 min-h-40 w-64 overflow-hidden rounded bg-light py-2 shadow-700 focus:outline-none focus-visible:outline-0 sm:max-h-72 lg:h-72 2xl:h-auto 2xl:max-h-screen",
                        {
                            "border border-border-200 ltr:right-0 ltr:origin-top-right rtl:left-0 rtl:origin-top-left":
                                variant === "minimal",
                            "ltr:right-0 ltr:origin-top-right rtl:left-0 rtl:origin-top-left ltr:xl:right-auto ltr:xl:left-0 ltr:xl:origin-top-left rtl:xl:left-auto rtl:xl:right-0 rtl:xl:origin-top-right":
                                variant !== "minimal",
                        }
                    )}
                >
                    <Scrollbar
                        className="h-full w-full"
                        options={{
                            scrollbars: {
                                autoHide: "never",
                            },
                        }}
                    >
                        {groups?.map(({ id, name, slug, icon }) => {
                            const IconComponent = icon;

                            return (
                                <Menu.Item key={id}>
                                    {({ active }) => (
                                        // FIX: Add ref to Link component
                                        <div
                                            className={cn(
                                                "flex w-full items-center space-x-4 px-5 py-2.5 text-sm font-semibold capitalize transition duration-200 hover:text-accent focus:outline-0 focus-visible:outline-0 rtl:space-x-reverse",
                                                active ? "text-accent" : "text-body-dark"
                                            )}
                                            onClick={() => {
                                                if (onItemClick) {
                                                    onItemClick({
                                                        id,
                                                        name,
                                                        icon,
                                                    });
                                                }
                                            }}
                                        >
                                            {/* <Link
                                                href={`/${slug}`}
                                                className={cn(
                                                    "flex w-full items-center space-x-4 px-5 py-2.5 text-sm font-semibold capitalize transition duration-200 hover:text-accent focus:outline-0 focus-visible:outline-0 rtl:space-x-reverse",
                                                    active ? "text-accent" : "text-body-dark"
                                                )}
                                            >
                                            </Link> */}
                                            {icon && variant === "colored" && (
                                                <span className="flex h-5 w-5 items-center justify-center">
                                                    {typeof icon === "string" ? (
                                                        getIcon({
                                                            iconList: groupIcons,
                                                            iconName: icon,
                                                            className:
                                                                "max-h-full max-w-full bg-reg-400",
                                                        })
                                                    ) : (
                                                        <IconComponent />
                                                    )}
                                                </span>
                                            )}
                                            <span className="w-38">{name}</span>
                                        </div>
                                    )}
                                </Menu.Item>
                            );
                        })}
                    </Scrollbar>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

type GroupsDropdownMenuProps = {
    variant?: "colored" | "minimal";
    groups?: Type[];
    defaultGroup?: Type;
    showIcon?: boolean;
    className?: string;
    onItemClick?: (item: Type) => void;
};

const GroupsDropdownMenu: React.FC<GroupsDropdownMenuProps> = ({
    variant,
    groups,
    defaultGroup,
    showIcon,
    className,
    onItemClick,
}) => {
    // const { types }: any = useTypes({
    //     limit: TYPES_PER_PAGE,
    // });
    //FIXME: remove this
    const { homePage }: any = useHomepage();

    return (
        <GroupsMenu
            groups={groups}
            defaultGroup={defaultGroup}
            variant={variant}
            showIcon={showIcon}
            className={className}
            onItemClick={onItemClick}
        />
    );
};

export default GroupsDropdownMenu;
