import React from 'react';
export const WomenDress: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.857"
      height="18"
      viewBox="0 0 12.857 18"
      fill="currentColor"
      {...props}
    >
      <g data-name="Women Dress">
        <g data-name="Group 12535">
          <path
            data-name="Path 21709"
            d="M6.428 3.214a.322.322 0 01-.294-.191 2.356 2.356 0 00-1.4-1.106.321.321 0 01-.237-.31V.321a.322.322 0 01.643 0v1.057a1.367 1.367 0 002.573 0V.321a.322.322 0 11.643 0v1.286a.321.321 0 01-.237.31 2.378 2.378 0 00-1.4 1.106.321.321 0 01-.291.191z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12536">
          <path
            data-name="Path 21710"
            d="M6.429 6.431a2.665 2.665 0 01-1.828-.732.321.321 0 01-.1-.235 6.921 6.921 0 00-.313-1.546 7.006 7.006 0 01-.329-1.668.321.321 0 01.269-.317c.013 0 .374-.071.374-.325a.322.322 0 01.643 0 .948.948 0 01-.627.873 9.545 9.545 0 00.291 1.267 8.492 8.492 0 01.331 1.567 2.007 2.007 0 002.583 0 8.493 8.493 0 01.331-1.567 9.584 9.584 0 00.291-1.267.948.948 0 01-.627-.873.322.322 0 01.643 0c0 .254.361.323.376.326a.324.324 0 01.266.317 7.006 7.006 0 01-.329 1.668 6.927 6.927 0 00-.313 1.546.321.321 0 01-.1.235 2.665 2.665 0 01-1.832.731z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12537">
          <path
            data-name="Path 21711"
            d="M6.429 17.999a4.744 4.744 0 01-2.652-.624 4.9 4.9 0 01-3.661-.737.321.321 0 010-.489c1.915-1.662 2.168-3.8 2.412-5.872.234-1.979.455-3.848 2.106-5.073a.321.321 0 11.383.516c-1.429 1.06-1.624 2.715-1.851 4.632-.235 1.985-.5 4.223-2.33 6.006a4.843 4.843 0 002.96.36.32.32 0 01.283.079 5.241 5.241 0 004.711 0 .314.314 0 01.28-.076 4.871 4.871 0 002.96-.36c-1.831-1.784-2.1-4.021-2.33-6.006-.227-1.917-.422-3.572-1.851-4.632a.321.321 0 01.383-.516c1.652 1.225 1.873 3.094 2.106 5.073.245 2.069.5 4.209 2.412 5.872a.321.321 0 010 .489 4.9 4.9 0 01-3.661.737 4.745 4.745 0 01-2.66.621z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12538">
          <path
            data-name="Path 21712"
            d="M9.321 16.072a.321.321 0 01-.239-.107c-.912-1.017-1.085-3.146-1.237-5.025a10.662 10.662 0 00-.424-2.772.321.321 0 11.585-.266 10.579 10.579 0 01.479 2.986c.144 1.777.307 3.792 1.075 4.647a.321.321 0 01-.239.536z"
            fill="currentColor"
          />
        </g>
        <g data-name="Group 12539">
          <path
            data-name="Path 21713"
            d="M3.535 16.072a.321.321 0 01-.239-.536c.767-.856.931-2.87 1.074-4.647a10.576 10.576 0 01.479-2.986.321.321 0 11.585.266 10.659 10.659 0 00-.424 2.772c-.152 1.879-.324 4.008-1.237 5.025a.321.321 0 01-.238.106z"
            fill="currentColor"
          />
        </g>
      </g>
    </svg>
  );
};
