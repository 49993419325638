import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BusinessProfileResponseModel, WebLayout } from "../../types";
import { useBusinessProfile } from "service/hook/customer";
import CustomerBusinessLayout1 from "components/layouts/customer/BusinessLayout1/CustomerBusinessLayout1";
import CustomerBusinessLayout2 from "components/layouts/customer/BusinessLayout2/CustomerBusinessLayout2";

const BusinessView = () => {
  const navigate = useNavigate();

  const { webUrl, nationality } = useParams();
  const { mutate: businessProfileMutate } = useBusinessProfile();
  const [businessProfile, setBusinessProfile] =
    useState<BusinessProfileResponseModel>();

  useEffect(() => {
    if (webUrl) {
      getBusinessProfile();
    } else {
      navigate("/");
    }
  }, [webUrl]);

  const getBusinessProfile = () => {
    businessProfileMutate(
      {
        web_url: webUrl ?? "",
      },
      {
        onSuccess: (successData: BusinessProfileResponseModel) => {
          if (successData.final_result_code === "000") {
            setBusinessProfile(successData);
          }
        },
      }
    );
  };

  const showComponentLayout = () => {
    if (businessProfile?.web_layout_details.web_layout_address2) {
      switch (businessProfile.web_layout_details.web_layout_address2) {
        case "CustomerBusinessLayout1":
          return (
            <CustomerBusinessLayout1 businessProfileData={businessProfile} />
          );
        case "CustomerBusinessLayout2":
          return (
            <CustomerBusinessLayout2 businessProfileData={businessProfile} />
          );
        default:
          return;
      }
    }
  };

  return <>{showComponentLayout()}</>;
};

export default BusinessView;
