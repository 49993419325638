import cn from "classnames";
import { LogoPlaceholder } from "../../utils/placeholders";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
// import Link from '@/components/ui/link';
// import { logoPlaceholder } from '@/lib/placeholders';
// import { useSettings } from '@/framework/settings';

const Logo: React.FC<React.AnchorHTMLAttributes<{}>> = ({ className, ...props }) => {
    // const {
    //   settings: { logo, siteTitle },
    // }: any = useSettings();
    return (
        <Link to="/" className={cn("inline-flex", className)} {...props}>
            <span className="relative h-10 w-32 overflow-hidden md:w-40 img-center">
                <img
                    src={LogoPlaceholder}
                    alt={"PickBazar Logo"}
                    sizes="(max-width: 768px) 100vw"
                    loading="eager"
                    className="object-contain"
                />
            </span>
        </Link>
    );
};

export default Logo;
