export const RelaxChair: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="60"
      viewBox="0 0 45 60"
      fill="currentColor"
      {...props}
    >
      <g
        id="Relax_chair"
        data-name="Relax chair"
        transform="translate(-48 -584)"
      >
        <path
          id="Path_17415"
          data-name="Path 17415"
          d="M82.71,587.219A3.751,3.751,0,0,0,79,584H63a3.751,3.751,0,0,0-3.713,3.219L56,614H86Zm-21.562.25A1.883,1.883,0,0,1,63,585.875H79a1.883,1.883,0,0,1,1.853,1.595l3.029,24.655H58.12Z"
          transform="translate(-0.5)"
          fill="currentColor"
        />
        <path
          id="Path_17416"
          data-name="Path 17416"
          d="M91.125,608H89.25a1.875,1.875,0,0,0-1.875,1.875v1.875a1.875,1.875,0,0,0,1.875,1.875v7.5H87.375a3.75,3.75,0,0,0-3.75-3.75H57.375a3.75,3.75,0,0,0-3.75,3.75H51.75v-7.5a1.875,1.875,0,0,0,1.875-1.875v-1.875A1.875,1.875,0,0,0,51.75,608H49.875A1.875,1.875,0,0,0,48,609.875v1.875a1.875,1.875,0,0,0,1.875,1.875v7.5A1.875,1.875,0,0,0,51.75,623h2.38a3.747,3.747,0,0,0,3.245,1.875H66.75v3.75a1.875,1.875,0,0,0,1.875,1.875h.938v1.875H63.938a4.693,4.693,0,0,0-4.687,4.688v2.976a2.813,2.813,0,1,0,1.875,0v-2.976a2.816,2.816,0,0,1,2.813-2.812h5.625v5.788a2.813,2.813,0,1,0,1.875,0V634.25h5.625a2.816,2.816,0,0,1,2.813,2.813v2.976a2.813,2.813,0,1,0,1.875,0v-2.976a4.693,4.693,0,0,0-4.687-4.687H71.438V630.5h.938a1.875,1.875,0,0,0,1.875-1.875v-3.75h9.375A3.744,3.744,0,0,0,86.869,623H89.25a1.875,1.875,0,0,0,1.875-1.875v-7.5A1.875,1.875,0,0,0,93,611.75v-1.875A1.875,1.875,0,0,0,91.125,608Zm-41.25,3.75v-1.875H51.75v1.875Zm10.313,31.875a.938.938,0,1,1,.938-.937A.939.939,0,0,1,60.188,643.625Zm20.625-1.875a.938.938,0,1,1-.937.938A.939.939,0,0,1,80.813,641.75ZM70.5,643.625a.938.938,0,1,1,.938-.937A.939.939,0,0,1,70.5,643.625Zm1.875-15h-3.75v-3.75h3.75ZM83.625,623H57.375a1.875,1.875,0,0,1,0-3.75h26.25a1.875,1.875,0,0,1,0,3.75Zm7.5-11.25H89.25v-1.875h1.875Z"
          transform="translate(0 -1.5)"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
