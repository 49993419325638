import { create } from "zustand";
import { MarketPlaceShowItem, ProductSearchGridShowItem } from "../types";

export interface PubState {
    searchItems: ProductSearchGridShowItem[];
    setSearchItems: (items: ProductSearchGridShowItem[]) => void;
    marketPlaceItems: MarketPlaceShowItem[];
    setMarketPlaceItems: (items: MarketPlaceShowItem[]) => void;
};


const pubStore = create<PubState>()(
    (set) => ({
        searchItems: [],
        setSearchItems: (items: ProductSearchGridShowItem[]) => set((state) => ({ searchItems: items })),
        marketPlaceItems: [],
        setMarketPlaceItems: (items: MarketPlaceShowItem[]) => set((state) => ({ marketPlaceItems: items })),
    })

)

export default pubStore;
