import React from "react";
import { Product, ProductSearchGridShowItem } from "../../../types";
// import type { Product } from "@/types";
// import dynamic from "next/dynamic";
const Helium = React.lazy(() => import("./helium"));
const Xenon = React.lazy(() => import("./xenon")); // furniture-two
// const Xenon = React.lazy(() => import("./xenon")); // furniture-two
const Radon = React.lazy(() => import("./radon")); // Book
// const Neon = dynamic(() => import("@/components/products/cards/neon")); // grocery-two
// const Argon = dynamic(() => import("@/components/products/cards/argon")); // bakery
// const Krypton = dynamic(
//     () => import("@/components/products/cards/krypton") // furniture extra price

const Xenon_V2 = React.lazy(() => import("./xenon_v2"));
// );

const MAP_PRODUCT_TO_CARD: Record<string, any> = {
    // neon: Neon,
    helium: Helium,
    // argon: Argon,
    // krypton: Krypton,
    xenon: Xenon,
    // radon: Radon,
};
interface ProductCardProps {
    product: Product;
    className?: string;
    cardType?: any;
}

interface ProductCardProps_V2 {
    product: ProductSearchGridShowItem;
    className?: string;
    cardType?: any;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, className, ...props }) => {
    const Component = product?.type?.settings?.productCard
        ? MAP_PRODUCT_TO_CARD[product?.type?.settings?.productCard]
        : Helium;
    return <Component product={product} {...props} className={className} />;
};

export const ProductCard_V2: React.FC<ProductCardProps_V2> = ({ product, className, ...props }) => {
    // const Component = product?.type?.settings?.productCard
    //     ? MAP_PRODUCT_TO_CARD[product?.type?.settings?.productCard]
    //     : Helium;
    const Component = Xenon_V2;
    return <Component product={product} {...props} className={className} />;
};

export default ProductCard;
