import ClassicLayout from "../../components/layouts/classic";
import { Header } from "../../components";
// import { useWindowSize } from "react-use";
// import HomeLayout from "../home";
// import HomeLayout from "@/components/layouts/_home";
// import Seo from "@/components/seo/seo";
// import { useWindowSize } from "@/lib/use-window-size";
// import CartCounterButton from "@/components/cart/cart-counter-button";
// import Classic from "@/components/layouts/classic";
// import Standard from "@/components/layouts/standard";
// import Modern from "@/components/layouts/modern";
// import Minimal from "@/components/layouts/minimal";
// import Compact from "@/components/layouts/compact";

const MAP_LAYOUT_TO_GROUP = {
    classic: ClassicLayout,
    // modern: Modern,
    // standard: Standard,
    // minimal: Minimal,
    // compact: Compact,
    // default: Classic,
};

// const RootView = ({ variables, layout }: any) => {
//     const { width } = useWindowSize();
//     const [type, setType] = useState(null);

//     useEffect(() => {
//         if (window.location.search) {
//             // scroller.scrollTo("grid", {
//             //     smooth: true,
//             //     offset: -110,
//             // });
//         }

//         // شبیه‌سازی دریافت داده‌های type از منبع دلخواه (این قسمت باید به شکل مناسب تغییر کند)
//         const fetchData = async () => {
//             try {
//                 // در اینجا داده‌های مرتبط با type از منبع خود را دریافت کنید
//                 const response = await fetch("YOUR_API_ENDPOINT");
//                 const data = await response.json();
//                 setType(data);
//             } catch (error) {
//                 console.error("Error fetching data:", error);
//             }
//         };

//         fetchData();
//     }, []);

//     const Component: any = MAP_LAYOUT_TO_GROUP.classic;

//     return (
//         <>
//             {/* <Seo title={type?.name} url={type?.slug} images={type?.banners} /> */}
//             <Component variables={variables} />
//             {/* {!["compact", "minimal"].includes(layout) && width > 1023 && <CartCounterButton />} */}
//         </>
//     );
// };

// RootView.getLayout = function getLayout(page: any) {
//     return <HomeLayout layout={page.props.layout}>{page}</HomeLayout>;
// };

const RootView = ({ children }: any) => {
    const layout = (
        <ClassicLayout
            variables={{
                categories: [],
            }}
        />
    );

    return (
        <div className="flex flex-col bg-gray-100 transition-colors duration-150">
            {/* <Header /> */}
            <div
                className="min-h-screen"
                style={{
                    marginTop: "5.25rem",
                }}
            >
                {layout}
            </div>
        </div>
    );
};

export default RootView;
