import { create, StateCreator } from "zustand";
import { persist, PersistOptions, createJSONStorage } from "zustand/middleware";

export type AuthState = {
  accessToken: string;
  setAccessToken: (token: string) => void;
  email: string;
  setEmail: (token: string) => void;
  isAuthorized: boolean;
  setAuthorized: (isAuthorized: boolean) => void;
};

type AuthPersist = (
  config: StateCreator<AuthState>,
  options: PersistOptions<AuthState>
) => StateCreator<AuthState>;

export const authStore = create<AuthState>(
  (persist as unknown as AuthPersist)(
    (set) => ({
      accessToken: "",
      setAccessToken: (accessToken: string) => set({ accessToken }),
      email: "",
      setEmail: (email: string) => set({ email }),
      isAuthorized: false,
      setAuthorized: (isAuthorized: boolean) => set({ isAuthorized }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default authStore;
