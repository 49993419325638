import React from "react";
import { PRODUCTS_PER_PAGE } from "../../../service/client/variables";
import { Grid, Grid_V2 } from "../grid";
import pubStore from "../../../store/pubStore";
// import { useProducts } from "@/framework/product";
// import { PRODUCTS_PER_PAGE } from "@/framework/client/variables";
// import { Grid } from "@/components/products/grid";

// import { useRouter } from "next/router";
interface Props {
    className?: string;
    variables: any;
    column?: any;
    gridClassName?: string;
}
export default function ProductGridHome({ className, variables, column, gridClassName }: Props) {
    const { searchItems } = pubStore((state) => state);

    // const { query } = useRouter();
    // const { products, loadMore, isLoadingMore, isLoading, hasMore, error } = useProducts({
    //     ...variables,
    //     ...(query.category && { categories: query.category }),
    //     ...(query.text && { name: query.text }),
    // });
    const productsItem: any = [
        {
            id: 111,
            name: "Mango Self Striped A Line Dress",
            slug: "mango-self-striped-a-line-dress",
            description:
                "Off-White self-striped knitted midi A-line dress, has a scoop neck, sleeveless, straight hem",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "112",
                original: "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/112/mango.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/112/conversions/mango-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T17:56:41.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 5,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 5,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 68,
                    name: "Women Dress",
                    slug: "women-dress",
                    language: "en",
                    icon: "WomenDress",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T06:25:14.000000Z",
                    updated_at: "2021-03-09T06:25:14.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        category_id: 68,
                    },
                },
                {
                    id: 71,
                    name: "Single Color",
                    slug: "single-color",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 68,
                    type_id: 5,
                    created_at: "2021-03-09T06:27:12.000000Z",
                    updated_at: "2021-03-09T06:27:12.000000Z",
                    deleted_at: null,
                    parent_id: 68,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        category_id: 71,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 111,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 255,
                    title: "Red/S",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200120",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 256,
                    title: "Red/M",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200121",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 257,
                    title: "Red/L",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200122",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 258,
                    title: "Blue/S",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200123",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 259,
                    title: "Blue/M",
                    image: null,
                    price: "81",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200124",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 260,
                    title: "Blue/L",
                    image: null,
                    price: "80",
                    sale_price: "70",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200125",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 111,
                    created_at: "2021-05-10T09:12:37.000000Z",
                    updated_at: "2021-05-10T09:12:37.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 113,
            name: "Forever 21 Solid Bodycon Midi Dress",
            slug: "forever-21-solid-bodycon-midi-dress",
            description:
                "Grey solid woven bodycon dress, has a round neck, sleeveless, straight hem",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "115",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/115/FOREVER_21.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/115/conversions/FOREVER_21-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "672",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/671/Striped-Dress.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/671/conversions/Striped-Dress-thumbnail.jpg",
                },
                {
                    id: "768",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/767/FOREVER_21.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/767/conversions/FOREVER_21-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:01:23.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 3,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 3,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 68,
                    name: "Women Dress",
                    slug: "women-dress",
                    language: "en",
                    icon: "WomenDress",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T06:25:14.000000Z",
                    updated_at: "2021-03-09T06:25:14.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        category_id: 68,
                    },
                },
                {
                    id: 70,
                    name: "Floral",
                    slug: "floral",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 68,
                    type_id: 5,
                    created_at: "2021-03-09T06:26:52.000000Z",
                    updated_at: "2021-03-09T06:26:52.000000Z",
                    deleted_at: null,
                    parent_id: 68,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        category_id: 70,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 14,
                    slug: "xl",
                    attribute_id: 4,
                    value: "XL",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:39.000000Z",
                    updated_at: "2021-05-09T17:45:39.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 113,
                        attribute_value_id: 14,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 261,
                    title: "Blue/M",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200130",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 262,
                    title: "Blue/L",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200131",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 263,
                    title: "Blue/XL",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200132",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 264,
                    title: "White/M",
                    image: null,
                    price: "120",
                    sale_price: null,
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200135",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 265,
                    title: "White/L",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200136",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 266,
                    title: "White/XL",
                    image: null,
                    price: "120",
                    sale_price: "100",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200138",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 113,
                    created_at: "2021-05-10T09:14:04.000000Z",
                    updated_at: "2021-05-10T09:14:04.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 114,
            name: "Dorothy Perkins Self Striped A Line Dress",
            slug: "dorothy-perkins-self-striped-a-line-dress",
            description:
                "Rust red self-striped knitted A-line dress, has a V-neck with button detailing, three-quarter sleeves, flared hem",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "116",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/116/DOROTHY_PERKINS.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/116/conversions/DOROTHY_PERKINS-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "674",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/673/Striped-Dress-2.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/673/conversions/Striped-Dress-2-thumbnail.jpg",
                },
                {
                    id: "675",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/674/Striped-Dress.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/674/conversions/Striped-Dress-thumbnail.jpg",
                },
                {
                    id: "769",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/768/DOROTHY_PERKINS.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/768/conversions/DOROTHY_PERKINS-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:02:33.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 68,
                    name: "Women Dress",
                    slug: "women-dress",
                    language: "en",
                    icon: "WomenDress",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T06:25:14.000000Z",
                    updated_at: "2021-03-09T06:25:14.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        category_id: 68,
                    },
                },
                {
                    id: 71,
                    name: "Single Color",
                    slug: "single-color",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 68,
                    type_id: 5,
                    created_at: "2021-03-09T06:27:12.000000Z",
                    updated_at: "2021-03-09T06:27:12.000000Z",
                    deleted_at: null,
                    parent_id: 68,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        category_id: 71,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 114,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 267,
                    title: "Red/S",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200140",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 268,
                    title: "Red/M",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 100,
                    is_disable: 0,
                    sku: "200141",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 269,
                    title: "Blue/S",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200142",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 270,
                    title: "Blue/M",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200143",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 271,
                    title: "White/S",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200144",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 272,
                    title: "White/M",
                    image: null,
                    price: "140",
                    sale_price: "130",
                    language: "en",
                    quantity: 500,
                    is_disable: 0,
                    sku: "200146",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 114,
                    created_at: "2021-05-10T09:15:47.000000Z",
                    updated_at: "2021-05-10T09:15:47.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 116,
            name: "Fold Over Collar Plain Blazers",
            slug: "fold-over-collar-plain-blazers",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "117",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/117/Fold_over.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/117/conversions/Fold_over-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "692",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/691/Plain-Blazers-4.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/691/conversions/Plain-Blazers-4-thumbnail.jpg",
                },
                {
                    id: "693",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/692/Plain-Blazers-5.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/692/conversions/Plain-Blazers-5-thumbnail.jpg",
                },
                {
                    id: "774",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/773/Fold_over.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/773/conversions/Fold_over-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:04:53.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 3,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 3,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        category_id: 72,
                    },
                },
                {
                    id: 75,
                    name: "Blazer",
                    slug: "blazer",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:20.000000Z",
                    updated_at: "2021-03-09T08:57:20.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        category_id: 75,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 14,
                    slug: "xl",
                    attribute_id: 4,
                    value: "XL",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:39.000000Z",
                    updated_at: "2021-05-09T17:45:39.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 116,
                        attribute_value_id: 14,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 273,
                    title: "Blue/M",
                    image: null,
                    price: "199",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200160",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 274,
                    title: "Blue/XL",
                    image: null,
                    price: "200",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200161",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 275,
                    title: "White/M",
                    image: null,
                    price: "200",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200162",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 276,
                    title: "White/XL",
                    image: null,
                    price: "200",
                    sale_price: null,
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200164",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "XL",
                        },
                    ],
                    product_id: 116,
                    created_at: "2021-05-10T09:16:55.000000Z",
                    updated_at: "2021-05-10T09:16:55.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 117,
            name: "Solid Notch Lapel Single Button Long Sleeve Blazer",
            slug: "solid-notch-lapel-single-button-long-sleeve-blazer",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "119",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/119/Solid_Notch.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/119/conversions/Solid_Notch-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "695",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/694/Plain-Blazers-3.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/694/conversions/Plain-Blazers-3-thumbnail.jpg",
                },
                {
                    id: "696",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/695/Plain-Blazers.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/695/conversions/Plain-Blazers-thumbnail.jpg",
                },
                {
                    id: "775",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/774/Solid_Notch.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/774/conversions/Solid_Notch-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:07:17.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 5,
            total_reviews: 1,
            rating_count: [
                {
                    rating: 5,
                    total: 1,
                    positive_feedbacks_count: 0,
                    negative_feedbacks_count: 0,
                    my_feedback: null,
                    abusive_reports_count: 0,
                },
            ],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 117,
                        category_id: 72,
                    },
                },
                {
                    id: 75,
                    name: "Blazer",
                    slug: "blazer",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:20.000000Z",
                    updated_at: "2021-03-09T08:57:20.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 117,
                        category_id: 75,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 117,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 117,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 117,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 117,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 292,
                    title: "Blue/S",
                    image: null,
                    price: "250",
                    sale_price: "220",
                    language: "en",
                    quantity: 220,
                    is_disable: 0,
                    sku: "220221",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 117,
                    created_at: "2021-05-10T09:25:08.000000Z",
                    updated_at: "2021-05-10T09:25:08.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 293,
                    title: "Blue/M",
                    image: null,
                    price: "250",
                    sale_price: "220",
                    language: "en",
                    quantity: 220,
                    is_disable: 0,
                    sku: "220220220",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 117,
                    created_at: "2021-05-10T09:25:08.000000Z",
                    updated_at: "2021-05-10T09:25:08.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 294,
                    title: "White/S",
                    image: null,
                    price: "250",
                    sale_price: "220",
                    language: "en",
                    quantity: 220,
                    is_disable: 0,
                    sku: "220220220220",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 117,
                    created_at: "2021-05-10T09:25:08.000000Z",
                    updated_at: "2021-05-10T09:25:08.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 295,
                    title: "White/M",
                    image: null,
                    price: "250",
                    sale_price: "220",
                    language: "en",
                    quantity: 220,
                    is_disable: 0,
                    sku: "220220220220220",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 117,
                    created_at: "2021-05-10T09:25:08.000000Z",
                    updated_at: "2021-05-10T09:25:08.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 118,
            name: "Notch Lapel Single Button Color Block Blazer",
            slug: "notch-lapel-single-button-color-block-blazer",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "120",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/120/Notch_Lapel.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/120/conversions/Notch_Lapel-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "698",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/697/Plain-Blazers-3.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/697/conversions/Plain-Blazers-3-thumbnail.jpg",
                },
                {
                    id: "699",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/698/Plain-Blazers.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/698/conversions/Plain-Blazers-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:08:44.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 118,
                        category_id: 72,
                    },
                },
                {
                    id: 75,
                    name: "Blazer",
                    slug: "blazer",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:20.000000Z",
                    updated_at: "2021-03-09T08:57:20.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 118,
                        category_id: 75,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 118,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 118,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 118,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 118,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 296,
                    title: "Red/S",
                    image: null,
                    price: "300",
                    sale_price: "250",
                    language: "en",
                    quantity: 250,
                    is_disable: 0,
                    sku: "250250250250",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 118,
                    created_at: "2021-05-10T09:26:06.000000Z",
                    updated_at: "2021-05-10T09:26:06.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 297,
                    title: "Red/L",
                    image: null,
                    price: "300",
                    sale_price: "250",
                    language: "en",
                    quantity: 250,
                    is_disable: 0,
                    sku: "250250255",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 118,
                    created_at: "2021-05-10T09:26:06.000000Z",
                    updated_at: "2021-05-10T09:26:06.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 298,
                    title: "Blue/S",
                    image: null,
                    price: "300",
                    sale_price: "250",
                    language: "en",
                    quantity: 250,
                    is_disable: 0,
                    sku: "250250256",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 118,
                    created_at: "2021-05-10T09:26:06.000000Z",
                    updated_at: "2021-05-10T09:26:06.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 299,
                    title: "Blue/L",
                    image: null,
                    price: "300",
                    sale_price: "250",
                    language: "en",
                    quantity: 250,
                    is_disable: 0,
                    sku: "250250250250250250",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 118,
                    created_at: "2021-05-10T09:26:06.000000Z",
                    updated_at: "2021-05-10T09:26:06.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 119,
            name: "Narrow Notch Lapel Slit Pocket Plain Blazers",
            slug: "narrow-notch-lapel-slit-pocket-plain-blazers",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "121",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/121/Narrow_Notch.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/121/conversions/Narrow_Notch-thumbnail.jpg",
            },
            video: null,
            gallery: [
                {
                    id: "703",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/702/Plain-Blazers-2.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/702/conversions/Plain-Blazers-2-thumbnail.jpg",
                },
                {
                    id: "704",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/703/Plain-Blazers-4.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/703/conversions/Plain-Blazers-4-thumbnail.jpg",
                },
                {
                    id: "705",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/704/Plain-Blazers-5.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/704/conversions/Plain-Blazers-5-thumbnail.jpg",
                },
                {
                    id: "777",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/776/Notch_Lapel.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/776/conversions/Notch_Lapel-thumbnail.jpg",
                },
            ],
            deleted_at: null,
            created_at: "2021-03-09T18:10:01.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 119,
                        category_id: 72,
                    },
                },
                {
                    id: 76,
                    name: "Waist Coat",
                    slug: "waist-coat",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:40.000000Z",
                    updated_at: "2021-03-09T08:57:40.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 119,
                        category_id: 76,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 119,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 119,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 119,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 119,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 304,
                    title: "Red/S",
                    image: null,
                    price: "260",
                    sale_price: null,
                    language: "en",
                    quantity: 260,
                    is_disable: 0,
                    sku: "260260",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 119,
                    created_at: "2021-05-10T09:28:14.000000Z",
                    updated_at: "2021-05-10T09:28:14.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 305,
                    title: "Red/M",
                    image: null,
                    price: "260",
                    sale_price: null,
                    language: "en",
                    quantity: 260,
                    is_disable: 0,
                    sku: "260260260",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 119,
                    created_at: "2021-05-10T09:28:14.000000Z",
                    updated_at: "2021-05-10T09:28:14.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 306,
                    title: "Blue/S",
                    image: null,
                    price: "260",
                    sale_price: null,
                    language: "en",
                    quantity: 260,
                    is_disable: 0,
                    sku: "260260260",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 119,
                    created_at: "2021-05-10T09:28:14.000000Z",
                    updated_at: "2021-05-10T09:28:14.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 307,
                    title: "Blue/M",
                    image: null,
                    price: "260",
                    sale_price: null,
                    language: "en",
                    quantity: 260,
                    is_disable: 0,
                    sku: "260260260",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 119,
                    created_at: "2021-05-10T09:28:14.000000Z",
                    updated_at: "2021-05-10T09:28:14.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 120,
            name: "Notch Lapel Elastic Waist Plain Trench Coat",
            slug: "notch-lapel-elastic-waist-plain-trench-coat",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: 160,
            shop_id: 2,
            sale_price: 135,
            language: "en",
            min_price: 160,
            max_price: 160,
            sku: "1209",
            quantity: 40,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "simple",
            unit: "1 pc(s)",
            height: null,
            width: null,
            length: null,
            image: {
                id: "122",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/122/Notch_Lapel_Elastic.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/122/conversions/Notch_Lapel_Elastic-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:11:21.000Z",
            updated_at: "2021-12-23T17:58:50.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 120,
                        category_id: 72,
                    },
                },
                {
                    id: 76,
                    name: "Waist Coat",
                    slug: "waist-coat",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:40.000000Z",
                    updated_at: "2021-03-09T08:57:40.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 120,
                        category_id: 76,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [],
            metas: [],
            manufacturer: null,
            variation_options: [],
            tags: [],
            author: null,
        },

        {
            id: 121,
            name: "Turn Down Collar Elastic Waist Plain Trench Coat",
            slug: "turn-down-collar-elastic-waist-plain-trench-coat",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: 160,
            shop_id: 2,
            sale_price: 145,
            language: "en",
            min_price: 160,
            max_price: 160,
            sku: "1210",
            quantity: 40,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "simple",
            unit: "1 pc(s)",
            height: null,
            width: null,
            length: null,
            image: {
                id: "123",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/123/Turn_Down.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/123/conversions/Turn_Down-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:12:33.000Z",
            updated_at: "2021-12-23T17:58:47.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 121,
                        category_id: 72,
                    },
                },
                {
                    id: 76,
                    name: "Waist Coat",
                    slug: "waist-coat",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:40.000000Z",
                    updated_at: "2021-03-09T08:57:40.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 121,
                        category_id: 76,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [],
            metas: [],
            manufacturer: null,
            variation_options: [],
            tags: [],
            author: null,
        },

        {
            id: 122,
            name: "Cowl Neck Kangaroo Pocket Color Block Hoodie",
            slug: "cowl-neck-kangaroo-pocket-color-block-hoodie",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: 95,
            shop_id: 2,
            sale_price: 75,
            language: "en",
            min_price: 95,
            max_price: 95,
            sku: "1212",
            quantity: 40,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "simple",
            unit: "1 pc(s)",
            height: null,
            width: null,
            length: null,
            image: {
                id: "124",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/124/Cowl_Neck.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/124/conversions/Cowl_Neck-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:13:51.000Z",
            updated_at: "2021-12-23T17:58:44.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 122,
                        category_id: 72,
                    },
                },
                {
                    id: 73,
                    name: "Hoodie",
                    slug: "hoodie",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:56:39.000000Z",
                    updated_at: "2021-03-09T08:56:39.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 122,
                        category_id: 73,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [],
            metas: [],
            manufacturer: null,
            variation_options: [],
            tags: [],
            author: null,
        },

        {
            id: 124,
            name: "Hooded Zips Plain Long Sleeve Hoodies",
            slug: "hooded-zips-plain-long-sleeve-hoodies",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "126",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/126/Hooded_Zips.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/126/conversions/Hooded_Zips-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:26:55.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 124,
                        category_id: 72,
                    },
                },
                {
                    id: 73,
                    name: "Hoodie",
                    slug: "hoodie",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:56:39.000000Z",
                    updated_at: "2021-03-09T08:56:39.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 124,
                        category_id: 73,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 124,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 124,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 124,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 124,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 289,
                    title: "Red/S",
                    image: null,
                    price: "125",
                    sale_price: null,
                    language: "en",
                    quantity: 125,
                    is_disable: 0,
                    sku: "125125",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 124,
                    created_at: "2021-05-10T09:24:10.000000Z",
                    updated_at: "2021-05-10T09:24:10.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 290,
                    title: "Red/M",
                    image: null,
                    price: "124",
                    sale_price: null,
                    language: "en",
                    quantity: 125,
                    is_disable: 0,
                    sku: "125125125",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 124,
                    created_at: "2021-05-10T09:24:10.000000Z",
                    updated_at: "2021-05-10T09:24:10.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 291,
                    title: "Red/L",
                    image: null,
                    price: "125",
                    sale_price: null,
                    language: "en",
                    quantity: 125,
                    is_disable: 0,
                    sku: "125125125125",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 124,
                    created_at: "2021-05-10T09:24:10.000000Z",
                    updated_at: "2021-05-10T09:24:10.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 126,
            name: "Lapel Zips Plain Long Sleeve Jackets",
            slug: "lapel-zips-plain-long-sleeve-jackets",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "127",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/127/Lapel_Zips.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/127/conversions/Lapel_Zips-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:28:39.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 126,
                        category_id: 72,
                    },
                },
                {
                    id: 73,
                    name: "Hoodie",
                    slug: "hoodie",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:56:39.000000Z",
                    updated_at: "2021-03-09T08:56:39.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 126,
                        category_id: 73,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 126,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 10,
                    slug: "white",
                    attribute_id: 3,
                    value: "White",
                    language: "en",
                    meta: "#e1e5ea",
                    created_at: "2021-05-09T16:11:35.000000Z",
                    updated_at: "2021-05-09T18:58:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 126,
                        attribute_value_id: 10,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 126,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 126,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 285,
                    title: "Red/S",
                    image: null,
                    price: "200",
                    sale_price: "150",
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150160",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 126,
                    created_at: "2021-05-10T09:23:29.000000Z",
                    updated_at: "2021-05-10T09:23:29.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 286,
                    title: "Red/M",
                    image: null,
                    price: "200",
                    sale_price: "150",
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150161",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 126,
                    created_at: "2021-05-10T09:23:29.000000Z",
                    updated_at: "2021-05-10T09:23:29.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 287,
                    title: "White/S",
                    image: null,
                    price: "200",
                    sale_price: "150",
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150162",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 126,
                    created_at: "2021-05-10T09:23:29.000000Z",
                    updated_at: "2021-05-10T09:23:29.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 288,
                    title: "White/M",
                    image: null,
                    price: "200",
                    sale_price: "150",
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150140",
                    options: [
                        {
                            name: "Color",
                            value: "White",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 126,
                    created_at: "2021-05-10T09:23:29.000000Z",
                    updated_at: "2021-05-10T09:23:29.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 128,
            name: "Boxy Cardigan",
            slug: "boxy-cardigan",
            description:
                "Blue and navy cotton Decima zipped cardigan from ANGLOZINE featuring a classic collar, a front zip fastening, a chest pocket, long sleeves, contrasting panels and a slim fit. This item is unisex.",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "130",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/130/BOXY_CARDIGAN.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/130/conversions/BOXY_CARDIGAN-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:30:33.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 128,
                        category_id: 72,
                    },
                },
                {
                    id: 73,
                    name: "Hoodie",
                    slug: "hoodie",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:56:39.000000Z",
                    updated_at: "2021-03-09T08:56:39.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 128,
                        category_id: 73,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 128,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 128,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 128,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 13,
                    slug: "l",
                    attribute_id: 4,
                    value: "L",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T17:45:30.000000Z",
                    updated_at: "2021-05-09T17:45:30.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 128,
                        attribute_value_id: 13,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 281,
                    title: "Red/M",
                    image: null,
                    price: "150",
                    sale_price: null,
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150150",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 128,
                    created_at: "2021-05-10T09:22:31.000000Z",
                    updated_at: "2021-05-10T09:22:31.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 282,
                    title: "Red/L",
                    image: null,
                    price: "150",
                    sale_price: null,
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150151",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 128,
                    created_at: "2021-05-10T09:22:31.000000Z",
                    updated_at: "2021-05-10T09:22:31.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 283,
                    title: "Blue/M",
                    image: null,
                    price: "150",
                    sale_price: null,
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "15015",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 128,
                    created_at: "2021-05-10T09:22:31.000000Z",
                    updated_at: "2021-05-10T09:22:31.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 284,
                    title: "Blue/L",
                    image: null,
                    price: "150",
                    sale_price: null,
                    language: "en",
                    quantity: 150,
                    is_disable: 0,
                    sku: "150153",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "L",
                        },
                    ],
                    product_id: 128,
                    created_at: "2021-05-10T09:22:31.000000Z",
                    updated_at: "2021-05-10T09:22:31.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 130,
            name: "Stretch Cotton Utility Jacket With Adjustable Waist",
            slug: "stretch-cotton-utility-jacket-with-adjustable-waist",
            description:
                "Blue and navy cotton Decima zipped cardigan from ANGLOZINE featuring a classic collar, a front zip fastening, a chest pocket, long sleeves, contrasting panels and a slim fit. This item is unisex.",
            type_id: 5,
            price: null,
            shop_id: 2,
            sale_price: null,
            language: "en",
            min_price: 300,
            max_price: 350,
            sku: null,
            quantity: 1500,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "variable",
            unit: "1 Stk",
            height: null,
            width: null,
            length: null,
            image: {
                id: "132",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/132/STRETCH_COTTON.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/132/conversions/STRETCH_COTTON-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:32:06.000Z",
            updated_at: "2022-07-04T15:32:26.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 130,
                        category_id: 72,
                    },
                },
                {
                    id: 73,
                    name: "Hoodie",
                    slug: "hoodie",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:56:39.000000Z",
                    updated_at: "2021-03-09T08:56:39.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 130,
                        category_id: 73,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [
                {
                    id: 8,
                    slug: "red",
                    attribute_id: 3,
                    value: "Red",
                    language: "en",
                    meta: "#ce1f6a",
                    created_at: "2021-05-09T16:10:56.000000Z",
                    updated_at: "2021-05-09T18:53:16.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 130,
                        attribute_value_id: 8,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 9,
                    slug: "blue",
                    attribute_id: 3,
                    value: "Blue",
                    language: "en",
                    meta: "#344fa1",
                    created_at: "2021-05-09T16:11:20.000000Z",
                    updated_at: "2021-05-09T18:52:35.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 130,
                        attribute_value_id: 9,
                    },
                    attribute: {
                        id: 3,
                        slug: "color",
                        language: "en",
                        name: "Color",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:31.000000Z",
                        updated_at: "2021-05-09T16:10:31.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 8,
                                slug: "red",
                                attribute_id: 3,
                                value: "Red",
                                language: "en",
                                meta: "#ce1f6a",
                                created_at: "2021-05-09T16:10:56.000000Z",
                                updated_at: "2021-05-09T18:53:16.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 9,
                                slug: "blue",
                                attribute_id: 3,
                                value: "Blue",
                                language: "en",
                                meta: "#344fa1",
                                created_at: "2021-05-09T16:11:20.000000Z",
                                updated_at: "2021-05-09T18:52:35.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 10,
                                slug: "white",
                                attribute_id: 3,
                                value: "White",
                                language: "en",
                                meta: "#e1e5ea",
                                created_at: "2021-05-09T16:11:35.000000Z",
                                updated_at: "2021-05-09T18:58:16.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 11,
                    slug: "s",
                    attribute_id: 4,
                    value: "S",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:43.000000Z",
                    updated_at: "2021-05-09T16:11:43.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 130,
                        attribute_value_id: 11,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
                {
                    id: 12,
                    slug: "m",
                    attribute_id: 4,
                    value: "M",
                    language: "en",
                    meta: null,
                    created_at: "2021-05-09T16:11:50.000000Z",
                    updated_at: "2021-05-09T16:11:50.000000Z",
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 130,
                        attribute_value_id: 12,
                    },
                    attribute: {
                        id: 4,
                        slug: "size",
                        language: "en",
                        name: "Size",
                        shop_id: 2,
                        created_at: "2021-05-09T16:10:40.000000Z",
                        updated_at: "2021-05-09T16:10:40.000000Z",
                        translated_languages: ["en"],
                        values: [
                            {
                                id: 11,
                                slug: "s",
                                attribute_id: 4,
                                value: "S",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:43.000000Z",
                                updated_at: "2021-05-09T16:11:43.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 12,
                                slug: "m",
                                attribute_id: 4,
                                value: "M",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T16:11:50.000000Z",
                                updated_at: "2021-05-09T16:11:50.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 13,
                                slug: "l",
                                attribute_id: 4,
                                value: "L",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:30.000000Z",
                                updated_at: "2021-05-09T17:45:30.000000Z",
                                translated_languages: ["en"],
                            },
                            {
                                id: 14,
                                slug: "xl",
                                attribute_id: 4,
                                value: "XL",
                                language: "en",
                                meta: null,
                                created_at: "2021-05-09T17:45:39.000000Z",
                                updated_at: "2021-05-09T17:45:39.000000Z",
                                translated_languages: ["en"],
                            },
                        ],
                    },
                },
            ],
            metas: [],
            manufacturer: null,
            variation_options: [
                {
                    id: 277,
                    title: "Red/S",
                    image: null,
                    price: "200",
                    sale_price: "180",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200170",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 130,
                    created_at: "2021-05-10T09:21:38.000000Z",
                    updated_at: "2021-05-10T09:21:38.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 278,
                    title: "Red/M",
                    image: null,
                    price: "200",
                    sale_price: "180",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200171",
                    options: [
                        {
                            name: "Color",
                            value: "Red",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 130,
                    created_at: "2021-05-10T09:21:38.000000Z",
                    updated_at: "2021-05-10T09:21:38.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 279,
                    title: "Blue/S",
                    image: null,
                    price: "200",
                    sale_price: "180",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200172",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "S",
                        },
                    ],
                    product_id: 130,
                    created_at: "2021-05-10T09:21:38.000000Z",
                    updated_at: "2021-05-10T09:21:38.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
                {
                    id: 280,
                    title: "Blue/M",
                    image: null,
                    price: "200",
                    sale_price: "180",
                    language: "en",
                    quantity: 200,
                    is_disable: 0,
                    sku: "200173",
                    options: [
                        {
                            name: "Color",
                            value: "Blue",
                        },
                        {
                            name: "Size",
                            value: "M",
                        },
                    ],
                    product_id: 130,
                    created_at: "2021-05-10T09:21:38.000000Z",
                    updated_at: "2021-05-10T09:21:38.000000Z",
                    is_digital: 0,
                    blocked_dates: [],
                },
            ],
            tags: [],
            author: null,
        },

        {
            id: 132,
            name: "David Jones",
            slug: "david-jones",
            description:
                "Black bandhgala, has a mandarin collar, a full button placket, long sleeves, three pockets, double vented back hem, and has an attached lining",
            type_id: 5,
            price: 105,
            shop_id: 2,
            sale_price: 84,
            language: "en",
            min_price: 105,
            max_price: 105,
            sku: "1217",
            quantity: 40,
            in_stock: 1,
            is_taxable: 0,
            shipping_class_id: null,
            status: "publish",
            product_type: "simple",
            unit: "1 pc(s)",
            height: null,
            width: null,
            length: null,
            image: {
                id: "134",
                original:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/134/David_Jones.jpg",
                thumbnail:
                    "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/134/conversions/David_Jones-thumbnail.jpg",
            },
            video: null,
            gallery: [],
            deleted_at: null,
            created_at: "2021-03-09T18:35:50.000Z",
            updated_at: "2021-12-23T17:58:11.000Z",
            author_id: null,
            manufacturer_id: null,
            is_digital: 0,
            is_external: 0,
            external_product_url: null,
            external_product_button_text: null,
            ratings: 0,
            total_reviews: 0,
            rating_count: [],
            my_review: null,
            in_wishlist: false,
            blocked_dates: [],
            translated_languages: ["en"],
            categories: [
                {
                    id: 72,
                    name: "Outer Wear",
                    slug: "outer-wear",
                    language: "en",
                    icon: "OuterWear",
                    image: [],
                    details: null,
                    parent: null,
                    type_id: 5,
                    created_at: "2021-03-09T08:55:55.000000Z",
                    updated_at: "2021-03-09T08:55:55.000000Z",
                    deleted_at: null,
                    parent_id: null,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 132,
                        category_id: 72,
                    },
                },
                {
                    id: 75,
                    name: "Blazer",
                    slug: "blazer",
                    language: "en",
                    icon: null,
                    image: [],
                    details: null,
                    parent: 72,
                    type_id: 5,
                    created_at: "2021-03-09T08:57:20.000000Z",
                    updated_at: "2021-03-09T08:57:20.000000Z",
                    deleted_at: null,
                    parent_id: 72,
                    translated_languages: ["en"],
                    pivot: {
                        product_id: 132,
                        category_id: 75,
                    },
                },
            ],
            shop: {
                id: 2,
                owner_id: 1,
                name: "Clothing Shop",
                slug: "clothing-shop",
                description:
                    "The clothing shop is the best shop around the city. This is being run under the store owner and our aim is to provide quality product and hassle free customer service.",
                cover_image: {
                    id: "886",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/Untitled-4.jpg",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/884/conversions/Untitled-4-thumbnail.jpg",
                },
                logo: {
                    id: "896",
                    original:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/fashion.png",
                    thumbnail:
                        "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/894/conversions/fashion-thumbnail.jpg",
                },
                is_active: 1,
                address: {
                    zip: "62656",
                    city: "Lincoln",
                    state: "Illinois",
                    country: "USA",
                    street_address: "4885  Spring Street",
                },
                settings: {
                    contact: "212901921221",
                    socials: [
                        {
                            url: "https://www.facebook.com/",
                            icon: "FacebookIcon",
                        },
                    ],
                    website: "https://redq.io/",
                    location: {
                        lat: 40.1576691,
                        lng: -89.38529779999999,
                        city: "Lincoln",
                        state: "IL",
                        country: "United States",
                        formattedAddress: "IL-121, Lincoln, IL, USA",
                    },
                },
                created_at: "2021-06-27T03:47:10.000000Z",
                updated_at: "2021-07-08T09:26:24.000000Z",
            },
            type: {
                id: 5,
                name: "Clothing",
                settings: {
                    isHome: false,
                    layoutType: "classic",
                    productCard: "xenon",
                },
                slug: "clothing",
                language: "en",
                icon: "DressIcon",
                promotional_sliders: [
                    {
                        id: 902,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/offer-5.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/902/conversions/offer-5-thumbnail.jpg",
                    },
                    {
                        id: 903,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/offer-4.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/903/conversions/offer-4-thumbnail.jpg",
                    },
                    {
                        id: 904,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/offer-3.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/904/conversions/offer-3-thumbnail.jpg",
                    },
                    {
                        id: 905,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/offer-2.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/905/conversions/offer-2-thumbnail.jpg",
                    },
                    {
                        id: 906,
                        original:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/offer-1.png",
                        thumbnail:
                            "https://pickbazarlaravel.s3.ap-southeast-1.amazonaws.com/906/conversions/offer-1-thumbnail.jpg",
                    },
                ],
                created_at: "2021-03-08T07:19:38.000000Z",
                updated_at: "2021-08-18T18:34:07.000000Z",
                translated_languages: ["en"],
            },
            variations: [],
            metas: [],
            manufacturer: null,
            variation_options: [],
            tags: [],
            author: null,
        },
    ];
    return (
        <div>
            <Grid
                products={productsItem}
                loadMore={() => {}}
                isLoading={false}
                isLoadingMore={false}
                hasMore={false}
                error={undefined}
                limit={PRODUCTS_PER_PAGE}
                className={className}
                gridClassName={gridClassName}
                column={column}
            />

            <div className="pl-8 mt-8">
                <span className="text-sm font-semibold text-heading md:text-base">
                    {"New products"}
                </span>
            </div>

            <Grid_V2
                products={searchItems}
                loadMore={() => {}}
                isLoading={false}
                isLoadingMore={false}
                hasMore={false}
                error={undefined}
                limit={PRODUCTS_PER_PAGE}
                className={className}
                gridClassName={gridClassName}
                column={column}
            />
        </div>
    );
}
