import React from "react";
import "./assets/css/main.css";
import "react-toastify/dist/ReactToastify.css";
import { ModalProvider } from "./provider/modal.context";
import AppRoute from "./routes/app.routs";
import ManagedModal from "./components/modal/managed-modal";
import QueryProvider from "./service/client/query-provider";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { CartProvider } from "./store/cart/cart.context";
import { SearchProvider } from "./components/ui/search/search.context";

function App() {
    return (
        <QueryProvider>
            <SearchProvider>
                <BrowserRouter>
                    <ModalProvider>
                        <CartProvider>
                            <AppRoute />
                            <ManagedModal />
                            <ToastContainer />
                        </CartProvider>
                    </ModalProvider>
                </BrowserRouter>
            </SearchProvider>
        </QueryProvider>
    );
}

export default App;
