import React from 'react';
export const MedicineIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.2"
      height="24.2"
      viewBox="0 0 24.2 24.2"
      fill="currentColor"
      {...props}
    >
      <g id="Medicine" transform="translate(-332.9 -1656.038)">
        <g
          id="Group_12311"
          data-name="Group 12311"
          transform="translate(333 1656.138)"
        >
          <path
            id="Path_17420"
            data-name="Path 17420"
            d="M350.833,1680.138a6.126,6.126,0,0,1-4.361-1.806l-11.666-11.666a6.167,6.167,0,0,1,8.722-8.722l11.666,11.666a6.167,6.167,0,0,1-4.361,10.528Zm-11.666-23.214a5.381,5.381,0,0,0-3.805,9.186l11.666,11.666a5.381,5.381,0,0,0,7.61,0h0a5.381,5.381,0,0,0,0-7.61L342.972,1658.5A5.346,5.346,0,0,0,339.167,1656.924Z"
            transform="translate(-333 -1656.138)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.2"
          />
        </g>
        <g
          id="Group_12312"
          data-name="Group 12312"
          transform="translate(340.524 1663.662)"
        >
          <line
            id="Line_3"
            data-name="Line 3"
            y1="8.166"
            x2="8.166"
            transform="translate(0.393 0.393)"
            fill="#fff"
            stroke="currentColor"
            strokeWidth="0.2"
          />
          <path
            id="Path_17421"
            data-name="Path 17421"
            d="M536.93,1868.628a.393.393,0,0,1-.278-.671l8.166-8.166a.393.393,0,0,1,.556.556l-8.166,8.166A.392.392,0,0,1,536.93,1868.628Z"
            transform="translate(-536.537 -1859.676)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.2"
          />
        </g>
        <g
          id="Group_12313"
          data-name="Group 12313"
          transform="translate(334.856 1659.668)"
        >
          <path
            id="Path_17423"
            data-name="Path 17423"
            d="M386.6,1759.337a.392.392,0,0,1-.278-.115,10.88,10.88,0,0,1-2.936-4.287,3.121,3.121,0,0,1,.659-3.167.393.393,0,1,1,.579.532,2.353,2.353,0,0,0-.482,2.417,10.161,10.161,0,0,0,2.737,3.949.393.393,0,0,1-.278.671Z"
            transform="translate(-383.216 -1751.641)"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.2"
          />
        </g>
      </g>
    </svg>
  );
};
