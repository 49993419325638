import { useTranslation } from "react-i18next";
import ContactForm from "../../settings/contact-form";
import contactIllustration from "../../../assets/contact-illustration.svg";

export default function Contact() {
    const { t } = useTranslation("common");
    return (
        <>
            {/*<Seo title={'Contact'} url={'contact'} />*/}
            <div className="w-full bg-gray-100 ">
                <div className="mx-auto flex w-full max-w-7xl flex-col px-5 py-10 pb-20 md:flex-row md:pb-10 xl:py-14 xl:px-8 xl:pb-14 2xl:px-14 md:mt-16">
                    {/* sidebar */}
                    <div className="order-2 w-full shrink-0 bg-light p-5 md:order-1 md:w-72 lg:w-96 rounded-md">
                        <div className="mb-8 flex w-full items-center justify-center overflow-hidden">
                            <img
                                src={contactIllustration}
                                alt={t("nav-menu-contact")}
                                className="h-auto w-full"
                            />
                        </div>
                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                {t("text-address")}
                            </span>
                            <span className="text-sm text-body">
                                {/*{!isEmpty(formatAddress(settings?.contactDetails?.location))*/}
                                {/*    ? formatAddress(settings?.contactDetails?.location)*/}
                                {/*    : */}
                                {/* {t("common:text-no-address")} */}
                                {t("London, United Kingdom")}
                            </span>
                        </div>

                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                {t("text-phone")}
                            </span>
                            <span className="text-sm text-body">
                                {/*{settings?.contactDetails?.contact*/}
                                {/*    ? settings?.contactDetails?.contact*/}
                                {/*    :*/}
                                {t("text-no-contact")}
                            </span>
                        </div>
                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">{t("Email")}</span>
                            <span className="text-sm text-body">
                                {/*{settings?.contactDetails?.contact*/}
                                {/*    ? settings?.contactDetails?.contact*/}
                                {/*    :*/}
                                {t("admin@milborak.co.uk")}
                            </span>
                        </div>
                        {/*{settings?.contactDetails?.website && (*/}
                        <div className="mb-8 flex flex-col">
                            <span className="mb-3 font-semibold text-heading">
                                {t("text-website")}
                            </span>
                            <div className="flex items-center justify-between">
                                <span className="text-sm text-body">
                                    {/*{settings?.contactDetails?.website}*/}
                                </span>
                                <a
                                    href={"https://milborak.co.uk"}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-sm font-semibold text-accent hover:text-accent-hover focus:text-blue-500 focus:outline-none"
                                >
                                    {t("www.milborak.co.uk")}
                                </a>
                            </div>
                        </div>
                        {/*)}*/}

                        <div className="mb-8 flex flex-col">
                            <span className="mb-4 font-semibold text-heading">
                                {t("text-follow-us")}
                            </span>
                            <div className="flex items-center justify-start">
                                {/*{settings?.contactDetails?.socials?.map(*/}
                                {/*    (item: any, index: number) => (*/}
                                {/*        <a*/}
                                {/*            key={index}*/}
                                {/*            href={item?.url}*/}
                                {/*            target="_blank"*/}
                                {/*            rel="noreferrer"*/}
                                {/*            className={`text-muted transition-colors duration-300 focus:outline-none ltr:mr-8 ltr:last:mr-0 rtl:ml-8 rtl:last:ml-0 hover:${item.hoverClass}`}*/}
                                {/*        >*/}
                                {/*            {getIcon({*/}
                                {/*                iconList: socialIcons,*/}
                                {/*                iconName: item?.icon,*/}
                                {/*                className: 'w-4 h-4',*/}
                                {/*            })}*/}
                                {/* icons */}
                                {/*</a>*/}
                                {/*)}*/}
                            </div>
                        </div>
                    </div>

                    {/* Contact form */}
                    <div className="order-1 mb-8 w-full rounded-lg bg-light p-5 md:order-2 md:mb-0 md:p-8 md:ml-7">
                        <h1 className="mb-7 font-body text-xl font-bold text-heading md:text-2xl">
                            {t("text-questions-comments")}
                        </h1>
                        <ContactForm />
                    </div>
                </div>
            </div>
        </>
    );
}
